<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Pembayaran Detail</b-card-title>
        <b-card-title class="text-primary">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            class="btn-icon"
            size="sm"
            :to="{name: 'tagihan'}"
          >
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
        </b-card-title>
      </b-card-header>

      <b-card-body>
        <b-form
          class="needs-validation"
          @submit.prevent="submitForm"
        >
          <b-form-row>
            <b-col
              md="10"
              offset-md="1"
            >
              <h6 class="pt-2 font-weight-bolder">Rincian Biaya</h6>

              <!-- Invoice Client & Payment Details -->
              <b-card-body class="invoice-padding pl-1">
                <b-row class="invoice-spacing">

                  <!-- Col: Payment Details -->
                  <b-col
                    cols="12"
                    xl="6"
                    class="p-0"
                  >
                    <div>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1">Nama Bank:</td>
                            <td>Bank {{bankVal}} {{kodeBankVal ? `(${kodeBankVal})` : ''}}</td>
                          </tr>
                          <tr>
                            <td class="pr-1">No Rekening:</td>
                            <td>{{noRekeningVal}}</td>
                          </tr>
                          <tr>
                            <td class="pr-1">Atas Nama:</td>
                            <td>{{namaVal}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>

                  <b-col
                    xl="6"
                    cols="12"
                    class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                  >
                    <div>
                      <table>
                        <tbody>
                          <tr>
                            <td class="pr-1">Subtotal:</td>
                            <td>Rp. {{subtotalVal}}</td>
                          </tr>
                          <tr>
                            <td class="pr-1">Tambahan Biaya:</td>
                            <td>Rp. {{tambahanBiayaVal}} ({{tambahanPersenVal}}%)</td>
                          </tr>
                          <tr>
                            <td class="pr-1">Potongan Harga:</td>
                            <td>Rp. {{potonganHargaVal}} ({{potonganPersenVal}}%)</td>
                          </tr>
                          <tr>
                            <td class="pr-1">Pajak:</td>
                            <td>Rp. {{pajakVal}} ({{pajakPersenVal}}%)</td>
                          </tr>
                          <tr>
                            <td class="pr-1">Total:</td>
                            <td>Rp. {{totalVal}}</td>
                          </tr>
                          <tr>
                            <td class="pr-1">Sisa Bayar:</td>
                            <td>Rp. {{sisaBayarVal}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <div v-if="dataPembayaran1.length > 0">
                <h6 class="pt-2 font-weight-bolder">Daftar Pembayaran</h6>
                <b-table
                  responsive
                  :items="dataPembayaran1"
                  :fields="fieldPembayaran"
                  small
                >
                  <template #cell(tanggalSetor)="data">
                    {{data.value}}
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-primary"
                      class="btn-icon rounded-circle"
                      @click="downloadFile(data.item.buktiSetor)"
                    >
                      <feather-icon icon="FileTextIcon" />
                    </b-button>
                  </template>

                  <template #cell()="data">
                    <span>{{ data.value }}</span>
                  </template>
                </b-table>
              </div>
            </b-col>
          </b-form-row>
        </b-form>
      </b-card-body>
    </b-card>

    <b-card
      title="Pembayaran yang Belum Dikonfirmasi"
      v-if="dataPembayaran0Val.length > 0"
    >
      <b-card-body>
        <vue-good-table
          :columns="columns"
          :rows="dataPembayaran0Val"
          :totalRows="10"
          :isLoading.sync="isLoading"
          mode="remote"
          :select-options="{
                        enabled: true,
                        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                        selectionInfoClass: 'custom-class',
                        selectionText: 'rows selected',
                        disableSelectInfo: true, // disable the select info panel on top
                        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                    }"
          @on-selected-rows-change="selectionChangedTable"
          :sort-options="{
                        enabled: false,
                    }"
          class="pb-1"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'tanggalSetor'"
              class="align-self-center"
            >
              {{props.row.tanggalSetor}}
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-primary"
                class="btn-icon rounded-circle"
                @click="downloadFile(props.row.buktiSetor)"
                v-b-tooltip.hover
                title="Bukti Setor"
              >
                <feather-icon icon="FileTextIcon" />
              </b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
        <b-col
          md="10"
          offset-md="1"
        >
          <b-col
            cols="12"
            class="text-right pr-0 pt-2"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="outline-secondary"
              class="mr-1"
              @click="submitKonfirmasi(false)"
            >
              <div v-if="isSubmit">
                <b-spinner small />
                <span class="sr-only">Loading...</span>
              </div>
              <div v-else>Batalkan</div>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              @click="submitKonfirmasi(true)"
            >
              <div v-if="isSubmit">
                <b-spinner small />
                <span class="sr-only">Loading...</span>
              </div>
              <div v-else>Konfirmasi</div>
            </b-button>
          </b-col>
        </b-col>
      </b-card-body>
    </b-card>

    <b-card title="Pembayaran">
      <b-card-body>
        <b-form
          class="needs-validation"
          @submit.prevent="submitForm"
        >
          <b-form-row>
            <b-col
              md="10"
              offset-md="1"
            >

              <b-form-group
                label="Tanggal Setor"
                label-for="tanggalSetor"
              >
                <b-input-group class="mb-1">
                  <b-form-input
                    id="tanggalSetor"
                    v-model="v$.tanggalSetor.$model"
                    type="text"
                    placeholder="DD-MM-YYYY"
                    autocomplete="off"
                    show-decade-nav
                    :state="validateState(v$.tanggalSetor)"
                    @input="changeTanggalSetor"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="tanggalSetor2"
                      show-decade-nav
                      button-only
                      button-variant="outline-primary"
                      right
                      size="sm"
                      locale="en-US"
                      aria-controls="example-input"
                      @context="onContextTanggalSetor"
                      :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric', }"
                      :max="maxTanggalSetor"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  class="text-danger"
                  v-for="(error, index) of v$.tanggalSetor.$errors"
                  :key="index"
                >
                  <span v-if="error.$validator == 'checkFormatDate'">Format Tanggal Tidak sesuai</span>
                  <span v-else>{{ error.$message }}</span>
                  <br>
                </small>
              </b-form-group>

              <!-- input jumlah -->
              <b-form-group
                label="jumlah"
                label-for="jumlah"
              >
                <b-form-input
                  v-model="v$.jumlah.$model"
                  id="jumlah"
                  :state="validateState(v$.jumlah)"
                  @keyup="keyupJumlah"
                  @keydown="changeIntOnly"
                />
                <b-form-invalid-feedback
                  v-for="(error, index) of v$.jumlah.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </b-form-invalid-feedback>
              </b-form-group>

              <!-- input buktiSetor -->
              <b-form-group
                label="Bukti Setor"
                label-for="buktiSetor"
              >
                <b-form-file
                  v-model="v$.buktiSetor.$model"
                  id="buktiSetor"
                  :state="validateState(v$.buktiSetor)"
                />
                <b-form-invalid-feedback
                  v-for="(error, index) of v$.buktiSetor.$errors"
                  :key="index"
                >
                  {{ error.$message }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-col
                cols="12"
                class="text-right pr-0 pt-2"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                >
                  <div v-if="isSubmit">
                    <b-spinner small />
                    <span class="sr-only">Loading...</span>
                  </div>
                  <div v-else>Simpan</div>
                </b-button>
              </b-col>
            </b-col>
          </b-form-row>
        </b-form>
      </b-card-body>
    </b-card>
  </div>

</template>

<script>
import {
    VBTooltip,
    BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner, BFormSelect, BRow, BInputGroup, BInputGroupAppend, BFormDatepicker, BFormFile, BTable
} from 'bootstrap-vue'
import { required, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import {ref, computed, watchEffect } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import {intThousand, setInputIntOnly} from '@/libs/helper'

const moment = require('moment');

export default {
    props: {
        totalVal: String,
        subtotalVal: String,
        tambahanBiayaVal: String,
        potonganHargaVal: String,
        pajakVal: String,
        tambahanPersenVal: String,
        potonganPersenVal: String,
        pajakPersenVal: String,
        sisaBayarVal: String,
        namaVal: String,
        bankVal: String,
        noRekeningVal: String,
        kodeBankVal: String,

        dataPembayaran0Val: Array,
        dataPembayaran1Val: Array,

        action: String,
        id: String
    },
    setup(props, context){
        const g = context.root

        var isCari = ref(false)
        var isSubmit = ref(false)
        const router = g.$router
        const $http = g.$http

        var maxTanggalSetor = ref(moment().format('YYYY-MM-DD'))
        var now = moment().format('DD-MM-YYYY')

        //form name
        var tanggalSetor = ref(now)
        var tanggalSetor2 = ref(now)
        var jumlah = ref('')
        var buktiSetor = ref([])
        var buktiSetorName = ref('')

        var dataPembayaran0 = ref([])
        var dataPembayaran1 = ref([])
        var dataSelectedPembayaran = ref([])

        var fieldPembayaran = ref([
            {
                key: 'no',
                label: 'No'
            },
            {
                key: 'tanggalSetor',
                label: 'Tanggal Setor'
            }, 
            {
                key: 'jumlah',
                label: 'Jumlah'
            },
        ])

        let isLoading = ref(false)

        watchEffect(() => {
            dataPembayaran0.value = props.dataPembayaran0Val
            dataPembayaran1.value = props.dataPembayaran1Val
        })

        const checkFormatDate = (val) => {
            if(typeof val !== 'undefined' && val != ''){
                let tanggal = moment(val, 'DD-MM-YYYY', true).format('DD-MM-YYYY');
                
                return tanggal == 'Invalid date' ? false : true;
            }
            return true
        }

        // validation
        const requiredMsg = 'tidak boleh kosong'
        const $externalResults = ref({})
        const rules = computed(() => ({
            tanggalSetor: {
                required: helpers.withMessage('Tanggal Setor '+requiredMsg, required),
                checkFormatDate
            },
            jumlah: {
                required: helpers.withMessage('Jumlah '+requiredMsg, required)
            },
            buktiSetor: {
                required: helpers.withMessage('Bukti Setor '+requiredMsg, required)
            }
        }))

        const v$ = useVuelidate(rules, { tanggalSetor, jumlah, buktiSetor }, {$externalResults, $autoDirty: true})

        const submitForm = async () => {
            isSubmit.value = true
            const isFormCorrect = await v$.value.$validate()
            // you can show some extra alert to the user or just leave the each field to show it's `$errors`.
            //jika error validasi
            if (!isFormCorrect) {
                isSubmit.value = false
                return
            }

            let fd = new FormData()
            // mapping data
            fd.append('tanggalSetor', tanggalSetor .value)
            fd.append('jumlah', jumlah.value)
            fd.append('buktiSetor', buktiSetor.value)
            if(buktiSetorName.value){
                let splitBuktiSetor = buktiSetorName.value.split('/')
                fd.append('buktiSetorName', splitBuktiSetor[splitBuktiSetor.length - 1])
            }
            if(props.id)
                fd.append('idTagihan', props.id)

            $http({
                url: '/tagihan/bayar/' + props.action,
                data: fd,
                method: 'post',
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    router.push({name: 'tagihan'})
                })
                .catch(err => {
                    isSubmit.value = false
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const downloadFile = file => {
            window.open(file)
        }

        const selectionChangedTable = (data) => {
            dataSelectedPembayaran.value = data.selectedRows;
        }


        const submitKonfirmasi = isKonfirmasi => {
            isSubmit.value = true

            let data = {
                isKonfirmasi,
                dataPembayaran: dataSelectedPembayaran.value
            }

            $http({
                url: '/tagihan/konfirmasi',
                data,
                method: 'post',
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    router.go(0)
                })
                .catch(err => {
                    isSubmit.value = false
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Submit Form`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        const changeTanggalSetor = () => {
            if(tanggalSetor.value){
                const tempTanggalSetor = moment(tanggalSetor.value, 'DD-MM-YYYY', true).format('YYYY-MM-DD')

                tanggalSetor2.value = tempTanggalSetor == 'Invalid date' ? '' : tempTanggalSetor
            }
        }

        const onContextTanggalSetor = ctx => {
            const tempTanggalSetor = moment(ctx.selectedYMD, 'YYYY-MM-DD').format('DD-MM-YYYY')

            tanggalSetor.value = tempTanggalSetor == 'Invalid date' ? tanggalSetor.value : tempTanggalSetor
        }

        const keyupJumlah = () => {
            jumlah.value = intThousand(jumlah.value)
        }

        const changeIntOnly = (evt) => {
            setInputIntOnly(evt)
        }

        const validateState = (item) => {
            const { $dirty, $error } = item
            return !$dirty ? null : $error ? false : null
        }

        return {
            v$, $externalResults, isCari, isLoading, isSubmit,
            tanggalSetor, maxTanggalSetor, tanggalSetor2, jumlah, buktiSetor, buktiSetorName,
            dataPembayaran0, dataPembayaran1, fieldPembayaran,
            validateState, submitForm, changeTanggalSetor, onContextTanggalSetor, keyupJumlah, changeIntOnly, downloadFile, selectionChangedTable, submitKonfirmasi
        }
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    components: {
        VueGoodTable,
        BCard, BForm, BFormRow, BCol, BFormInput, BFormValidFeedback, BFormInvalidFeedback, BCardHeader, BCardBody, BButton, BFormGroup, BCardTitle, BSpinner, BFormSelect, BRow, BInputGroup, BInputGroupAppend, BFormDatepicker, BFormFile, BTable
    },
    data() {
        return {
            columns: [
                {
                    label: 'No',
                    field: 'no',
                    width: '1%',
                },
                {
                    field: 'tanggalSetor',
                    label: 'Tanggal Setor'
                }, 
                {
                    field: 'jumlah',
                    label: 'Jumlah'
                },
            ]
        }
    }
}
</script>

<style lang="scss" scope>
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>