<template>
    <Form 
        :action="action"
        :id="id"

        :subtotalVal="subtotal"
        :totalVal="total"
        :tambahanBiayaVal="tambahanBiaya"
        :tambahanPersenVal="tambahanPersen"
        :potonganHargaVal="potonganHarga"
        :potonganPersenVal="potonganPersen"
        :pajakVal="pajak"
        :pajakPersenVal="pajakPersen"
        :sisaBayarVal="sisaBayar"
        :namaVal="nama"
        :bankVal="bank"
        :noRekeningVal="noRekening"
        :kodeBankVal="kodeBank"

        :dataPembayaran0Val="dataPembayaran0"
        :dataPembayaran1Val="dataPembayaran1"
     />
</template>

<script>
import Form from './Form.vue'
import {ref} from '@vue/composition-api'
import {convertIntToPersen} from '@/libs/helper'

export default {
    setup(props, context){
        const g = context.root

        const $http = g.$http

        var subtotal = ref('0')
        var total = ref('0')
        var tambahanBiaya = ref('0')
        var tambahanPersen = ref('0')
        var potonganHarga = ref('0')
        var potonganPersen = ref('0')
        var pajak = ref('0')
        var pajakPersen = ref('0')
        var sisaBayar = ref('0')
        var nama = ref('')
        var bank = ref('')
        var noRekening = ref('')
        var kodeBank = ref('')

        var dataPembayaran0 = ref([])
        var dataPembayaran1 = ref([])

        const getData = () => {
            $http({
                url: '/tagihan/detail/'+props.id,
                headers: {
                    Authorization: 'Bearer '+g.$cookies.get('token')
                }
            })
                .then(res => {
                    let {pajakVal, potonganHargaVal, subtotalVal, tambahanBiayaVal, totalVal, dataTagihan, sisaBayarVal, namaBankVal, bankVal, noRekeningVal, kodeBankVal,} = res.data.data
                    dataPembayaran0.value = dataTagihan.dataPembayaran0Val
                    dataPembayaran1.value = dataTagihan.dataPembayaran1Val
                    subtotal.value = subtotalVal ? subtotalVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'
                    total.value = totalVal ? totalVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'
                    sisaBayar.value = sisaBayarVal ? sisaBayarVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") : '0'
                    nama.value = namaBankVal
                    bank.value = bankVal
                    noRekening.value = noRekeningVal
                    kodeBank.value = kodeBankVal

                    if(tambahanBiayaVal){
                        tambahanPersen.value = convertIntToPersen(tambahanBiayaVal, subtotalVal)
                        tambahanBiaya.value = tambahanBiayaVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    }
                    else{
                        tambahanBiaya.value = '0'
                        tambahanPersen.value = '0'
                    }

                    if(potonganHargaVal){
                        potonganPersen.value = convertIntToPersen(potonganHargaVal, subtotalVal)
                        potonganHarga.value = potonganHargaVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    }
                    else{
                        potonganHarga.value = '0'
                        potonganPersen.value = '0'
                    }

                    if(pajakVal){
                        pajakPersen.value = convertIntToPersen(pajakVal, subtotalVal)
                        pajak.value = pajakVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
                    }
                    else{
                        pajak.value = '0'
                        pajakPersen.value = '0'
                    }
                })
                .catch(err => {
                    let showError = err.response ? err.response.data : err.request ? err.request : 'Error' + err.message;

                    g.$bvToast.toast(showError || 'Something Wrong', {
                        title: `Error Load Data`,
                        variant: 'danger',
                        solid: true,
                    })
                })
        }

        getData()

        return { 
            subtotal, total, tambahanBiaya, tambahanPersen, potonganHarga, potonganPersen, pajak, pajakPersen, sisaBayar, nama, bank, noRekening, kodeBank,
            dataPembayaran0, dataPembayaran1
        }
    },
    data(){
        return{
            action: 'Tambah'
        }
    },
    components: {
        Form
    },
    props: ['id']
}
</script>